exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dosing-js": () => import("./../../../src/pages/dosing.js" /* webpackChunkName: "component---src-pages-dosing-js" */),
  "component---src-pages-efficacy-js": () => import("./../../../src/pages/efficacy.js" /* webpackChunkName: "component---src-pages-efficacy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-long-term-data-js": () => import("./../../../src/pages/long-term-data.js" /* webpackChunkName: "component---src-pages-long-term-data-js" */),
  "component---src-pages-mechanism-of-action-js": () => import("./../../../src/pages/mechanism-of-action.js" /* webpackChunkName: "component---src-pages-mechanism-of-action-js" */),
  "component---src-pages-pharmacist-support-js": () => import("./../../../src/pages/pharmacist-support.js" /* webpackChunkName: "component---src-pages-pharmacist-support-js" */),
  "component---src-pages-request-a-representative-js": () => import("./../../../src/pages/request-a-representative.js" /* webpackChunkName: "component---src-pages-request-a-representative-js" */),
  "component---src-pages-rte-unsubscribe-js": () => import("./../../../src/pages/rte-unsubscribe.js" /* webpackChunkName: "component---src-pages-rte-unsubscribe-js" */),
  "component---src-pages-rte-unsubscribe-success-js": () => import("./../../../src/pages/rte-unsubscribe-success.js" /* webpackChunkName: "component---src-pages-rte-unsubscribe-success-js" */),
  "component---src-pages-safety-js": () => import("./../../../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-samples-support-js": () => import("./../../../src/pages/samples-support.js" /* webpackChunkName: "component---src-pages-samples-support-js" */),
  "component---src-pages-ui-kit-js": () => import("./../../../src/pages/ui-kit.js" /* webpackChunkName: "component---src-pages-ui-kit-js" */)
}

