import { useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import { throttle } from "lodash"

export const convertToSlug = _str => {
  return _str
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "")
}

export const useDocumentScrollThrottled = callback => {
  const [, setScrollPosition] = useState(0)
  let previousScrollTop = 0

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } =
      document.documentElement || document.body

    setScrollPosition(previousPosition => {
      previousScrollTop = previousPosition
      return currentScrollTop
    })

    callback({ previousScrollTop, currentScrollTop })
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250)

  useEffect(() => {
    window.addEventListener("scroll", handleDocumentScrollThrottled)

    return () =>
      window.removeEventListener("scroll", handleDocumentScrollThrottled)
  }, [])
}


export const handleNavigateClick = path => {
  navigate(path)
}

export const useIsMount = () => {
  const isMountRef = useRef(true)
  useEffect(() => {
    isMountRef.current = false
  }, [])
  return isMountRef.current
}

export const handleNavClick = (event, url) => {
  event.preventDefault()
  if (url && !url.includes(".pdf")) {
    url.includes("http") ? window.open(url, "new") : navigate(url)
  } else {
    const target = event.target
    if (
      (target.href && target.href.includes(".pdf")) ||
      target.classList.contains("external-link")
    ) {
      window.open(target.href, "_blank")
    } else if (url && url.includes(".pdf")) {
      window.open(url, "_blank")
    } else if (target.hash) {
      window.location.hash = target.hash
    } else {
      navigate(target.href)
    }
  }

  return
}

export const hideCookiePreference = () => {
  const floatingCookie = document.querySelector("#ot-sdk-btn-floating");
  // if the OneTrust floating button exists hide it
  if (floatingCookie) {
    document.querySelector("#ot-sdk-btn-floating").style.display = 'none';
  }
}

export const showCookiePreference = () => {
  const floatingButton = document.querySelector('#ot-sdk-btn-floating .ot-floating-button__front .ot-floating-button__open');
  if(floatingButton) {
    floatingButton.click();
  }

}

export const useWindowSize = () => {
  const isSSR = typeof window === "undefined"
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  })

  const changeWindowSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  useEffect(() => {
    window.addEventListener("resize", changeWindowSize)

    return () => {
      window.removeEventListener("resize", changeWindowSize)
    }
  }, [])

  return windowSize
}

export const isBrowser = typeof window !== "undefined"

export const stopScroll = (top, width) => {
  // hidden hidden explicity sets both overflow-x and overflow-y
  document.body.style.overflow = "hidden hidden"
  document.body.style.height = `${window.innerHeight}px` // 100vh breaks on ios
  document.body.style.position = "fixed" // without position fixed, ios allows background scroll
  document.body.style.bottom = "0"
  document.body.style.left = "0"
  document.body.style.right = "0"
  document.body.style.top = `-${top}px`

  if (!(window && window.innerWidth < 768)) {
    if (width) {
      const fixedIsiTray = document.getElementById("isiTray")
      if (fixedIsiTray) {
        fixedIsiTray.classList.add("p-shift")
      }

      document.body.style.paddingRight = `${width}px`
      const needsShift = document.querySelectorAll(".p-shift")
      needsShift.forEach(el => {
        el.style.paddingRight = `${width}px`
      })
    }
  }
}

export const resetScroll = () => {
  const scrolledHeight = document.body.style.top
  const scrolledHeightNum = scrolledHeight.slice(1, -2)
  document.body.style.removeProperty("overflow")
  document.body.style.removeProperty("height")
  document.body.style.removeProperty("position")
  document.body.style.removeProperty("top")
  document.body.style.removeProperty("bottom")
  document.body.style.removeProperty("left")
  document.body.style.removeProperty("right")

  if (!(window && window.innerWidth < 768)) {
    //if headroom is pinned, add p-shift and remove transition
    const headroom = document.querySelector(".headroom")
    if (headroom) {
      headroom.style.removeProperty("padding-right")
    }
    const isiTray = document.getElementById("isiTray")
    if (isiTray) {
      isiTray.style.removeProperty("padding-right")
    }

    document.body.style.removeProperty("padding-right")
    const needsShift = document.querySelectorAll(".p-shift")
    needsShift.forEach(el => {
      el.style.removeProperty("padding-right")
    })
  }
  if (scrolledHeightNum > 0) {
    window.scroll(0, scrolledHeightNum)
  }
}

export const pushDatalayerEvent = (eventName, fieldLabel = undefined) => {
  window.dataLayer &&
    window.dataLayer.push({ event: eventName, field: fieldLabel })
}

export const pageMapping = {
  safety: "AXS_AUV_BHCP_W_SAF",
  dosing: "AXS_AUV_BHCP_W_DOS",
  efficacy: "AXS_AUV_BHCP_W_EFF",
  "mechanism-of-action": "AXS_AUV_BHCP_W_MOA",
  "pharmacist-support": "AXS_AUV_BHCP_W_PHARM",
}
