import "@fontsource/poppins/400.css"
import "@fontsource/poppins/700.css"
import "@fontsource/poppins/800.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/900.css"
import "@fontsource/poppins/400-italic.css"
import "@fontsource/poppins/700-italic.css"
import "@fontsource/poppins/900-italic.css"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import Provider from "./src/context/Provider"

export const wrapRootElement = Provider
